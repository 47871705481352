import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { shapes } from 'images/hulp'
import { highlight_1, highlight_2, highlight_3,highlight_4 } from 'images/blog'
import CtaRow from 'components/cta-row'
import { documentIcon, documentTemplateIcon } from 'images/hulp/tools'
import { one } from 'images/product/salaris'
import { belastingdienst, belastingdienstIllustration, logoPlaceholder } from 'images'
import Layout from '../../../layouts'
import Helmet from 'react-helmet'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const slickSettings = {
  centerMode: true,
  centerPadding: '32px',
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 20000,
  draggable: true,
  variableWidth: true,
  dots: false,
  arrows: true,
  touchMove: false,
  infinite: true,
  responsive: [
    {
      breakpoint: 1120,
      settings: {
        touchMove: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
      }
    },
    {
      breakpoint: 768,
      settings: {
        centerPadding: '24px',
        touchMove: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
      }
    }
  ]
}

class AanvragenLoonbelastingnummerConfirmedPage extends React.Component {
  amountOfArticles = slug => {
    const articles = this.props.data.allContentfulArticle.edges
    let amount = 0

    articles.map((article) => {
      const catName = article.node.category.slug

      if (catName === slug) {
        amount += 1
      }
    })

    return amount
  }
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="tools kennisbank animated fadeInPage">

          <Helmet>
            <title>Tool loonbelastingnummer aanvragen | Employes</title>
            <meta name="description" content="Als je personeel in dienst hebt, moet je loonaangifte doen. Daar heb je een loonbelastingnummer voor nodig. Hieronder kun je het aanvraagformulier downloaden." />
            <meta itemprop="name" content="Tool loonbelastingnummer aanvragen | Employes" />
            <meta itemprop="description" content="Als je personeel in dienst hebt, moet je loonaangifte doen. Daar heb je een loonbelastingnummer voor nodig. Hieronder kun je het aanvraagformulier downloaden.." />
            <meta itemprop="image" content="/static/meta-img.png" />
            <meta name="robots" content="noindex,follow" />
          </Helmet>

          <header className="padding-xl">
            <div className="container-md">
              <div className="grid yg center text-center">
                <div className="col-12">
                  <p className="eyebrow">Tool</p>
                  <h1>De aanvraag is gelukt.</h1>
                  <p className="streamer center">Je ontvangt het formulier binnen enkele minuten in je inbox. Wil je meer weten over salarisadministratie? Bekijk de artikelen in ons blog! </p>
                </div>
              </div>
            </div>
          </header>

          <div className="articles padding-xxl-bottom">

            <div className="container-lg no-pad slick-slider-container">
              <h3 className="eyebrow center margin-xs-bottom">Handige artikelen</h3>

              <div className="grid yg">
                <Slider {...slickSettings}>

                  <div className="col-4 content-box">
                    <Link to="/blog/wat-is-salarisadministratie/">
                      <div className="articles-card no-margin">
                        <img src={highlight_1} />
                        <div className="articles-card-content">
                          <p>Wat is salarisadministratie?</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-4 content-box">
                    <Link to="/blog/beginnen-met-salarisadministratie/">
                      <div className="articles-card no-margin">
                        <img src={highlight_2} />
                        <div className="articles-card-content">
                          <p>Beginnen met salarisadministratie</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-4 content-box">
                    <Link to="/blog/loonheffingennummer-aanvragen/">
                      <div className="articles-card no-margin">
                        <img src={highlight_3} />
                        <div className="articles-card-content">
                          <p>Loonheffingennummer aanvragen</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-4 content-box">
                    <Link to="/blog/loonadministratie-zelf-doen/">
                      <div className="articles-card no-margin">
                        <img src={highlight_4} />
                        <div className="articles-card-content">
                          <p>Loonadministratie zelf doen</p>
                        </div>
                      </div>
                    </Link>
                  </div>

                </Slider>
              </div>
            </div>
          </div>


        </main>
      </Layout>
    )
  }
}

export default AanvragenLoonbelastingnummerConfirmedPage
